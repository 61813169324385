import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  useFSPointPaymentWithAmole,
  useGetAmoleOTP,
  useProductPaymentWithAmole,
  useSubscriptionPaymentWithAmole,
} from "../../hooks/Payment";
import { isEmpty } from "../../utils/validation";
import { message } from "../Account/Constants";
import { validPhone } from "../Account/ValidateField";
import Alert from "../Alert/Alert";
import { validateAmole } from "./Validation";

const useStyles = makeStyles((theme) => ({
  payment: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  button: {
    display: "flex",
    width: "50%",
    margin: theme.spacing(3, 0),
  },
}));

export default function AmolePay({ setAmole, body, type }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [notify, setNotify] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});

  const AMutate = useProductPaymentWithAmole();
  const BAMutate = useFSPointPaymentWithAmole();
  const SAMutate = useSubscriptionPaymentWithAmole();
  const OTPMutate = useGetAmoleOTP();

  const handleBlure = (e) => {
    if (e.target.value === "")
      setErrors((prev) => ({ ...prev, [e.target.name]: message.VALUE_ERROR }));
    else if (e.target.name === "card_number") {
      if (!validPhone(e.target.value) && !/^[0-9]{16}$/i.test(e.target.value)) {
        const msg = "Enter a valid card number or phone number.";
        setErrors((prev) => ({ ...prev, [e.target.name]: msg }));
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "card_number") {
      const eValue = e.target.value;
      if (validPhone(eValue) || /^[0-9]{16}$/i.test(eValue)) {
        setErrors((prev) => ({ ...prev, card_number: null }));
      }
      setValues((prev) => ({ ...prev, card_number: eValue, pin_type: null }));
    } else if (e.target.name === "pin_or_otp" && e.target.value < 10000) {
      setValues((prev) => ({ ...prev, pin_or_otp: e.target.value }));
      setErrors((prev) => ({ ...prev, pin_or_otp: null }));
    }
  };

  const handlePinOrOTP = (type) => {
    if (!values.card_number)
      setErrors((prev) => ({ ...prev, card_number: message.VALUE_ERROR }));

    if (values.card_number && !errors.card_number) {
      if (type === "OTP") OTPMutate.mutate(values);
      setErrors((prev) => ({ ...prev, pin_or_otp: null }));
      setValues((prev) => ({ ...prev, pin_type: type, pin_or_otp: null }));
    }
  };

  useEffect(() => {
    if (OTPMutate.isSuccess) {
      setErrors(OTPMutate.data);
      if (OTPMutate.data.detail) setNotify(true);
    }
    if (OTPMutate.isError) {
      setErrors(OTPMutate.error);
      if (OTPMutate.error.detail) setNotify(true);
    }
  }, [OTPMutate.isSuccess, OTPMutate.isError]);

  const handleSubmit = () => {
    const error = validateAmole(values);
    setErrors({ ...error, detail: null });
    if (isEmpty(error)) {
      body.pin_type = values.pin_type;
      body.card_number = values.card_number;
      body.pin_or_otp = values.pin_or_otp;

      if (type === "product_post") AMutate.mutate(body);
      else if (type === "subscription") SAMutate.mutate(body);
      else if (type === "point") BAMutate.mutate(body);
    }
  };

  useEffect(() => {
    if (AMutate.isSuccess && AMutate.data.success)
      navigate(`/detail/${body.product_pk}`);

    if (AMutate.isError) {
      setErrors(AMutate.error);
      if (AMutate.error.detail || AMutate.error.message) setNotify(true);
      AMutate.reset();
    }
  }, [AMutate.isError, AMutate.isSuccess]);

  useEffect(() => {
    if (BAMutate.isSuccess && BAMutate.data.success)
      navigate(`/profile/points`);

    if (BAMutate.isError) {
      setErrors(BAMutate.error);
      if (BAMutate.error.detail || BAMutate.error.message) setNotify(true);
      BAMutate.reset();
    }
  }, [BAMutate.isError, BAMutate.isSuccess]);

  useEffect(() => {
    if (SAMutate.isSuccess && SAMutate.data.success)
      navigate(`/profile/subscriptions`);

    if (SAMutate.isError) {
      setErrors(SAMutate.error);
      if (SAMutate.error.detail || SAMutate.error.message) setNotify(true);
      SAMutate.reset();
    }
  }, [SAMutate.isError, SAMutate.isSuccess]);

  return (
    <Box className={classes.payment}>
      <Backdrop
        sx={{
          position: "absolute",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={
          AMutate.isLoading ||
          OTPMutate.isLoading ||
          SAMutate.isLoading ||
          BAMutate.isLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h4" mb={3}>
        {t("Amole Payment options")}
      </Typography>
      <TextField
        name="card_number"
        label={t("Card number or Mobile number")}
        variant="outlined"
        size="small"
        sx={{ width: "50%" }}
        value={values.card_number}
        onChange={handleChange}
        onBlur={handleBlure}
        error={Boolean(errors.card_number)}
        helperText={t(errors.card_number)}
      />
      <Box className={classes.button}>
        <Button
          sx={{ width: "50%" }}
          variant={values.pin_type === "pin" ? "contained" : "outlined"}
          onClick={() => handlePinOrOTP("pin")}
        >
          {t("Enter pin")}
        </Button>
        <Typography variant="body2" color="text.secondary" m={1}>
          {t("or")}
        </Typography>
        <Button
          sx={{ width: "50%" }}
          variant={values.pin_type === "OTP" ? "contained" : "outlined"}
          onClick={() => handlePinOrOTP("OTP")}
        >
          {t("Get OTP")}
        </Button>
      </Box>

      {(values.pin_type === "pin" ||
        (values.pin_type === "OTP" &&
          OTPMutate.data &&
          OTPMutate.data.success)) && (
        <>
          <TextField
            name="pin_or_otp"
            label={values.pin_type === "pin" ? t("Enter pin") : t("Enter OTP")}
            variant="outlined"
            size="small"
            type="number"
            sx={{ width: "50%" }}
            value={values.pin_or_otp}
            onChange={handleChange}
            onBlur={handleBlure}
            error={Boolean(errors.pin_or_otp)}
            helperText={t(errors.pin_or_otp)}
          />
          <Button
            sx={{ width: "50%", mt: 2 }}
            variant="outlined"
            onClick={handleSubmit}
          >
            {t("Submit")}
          </Button>
        </>
      )}
      <Button
        variant="outlined"
        onClick={() => setAmole(false)}
        sx={{ bottom: 40, right: 60, position: "absolute" }}
      >
        {t("Back")}
      </Button>
      <Alert
        open={notify}
        setOpen={() => setNotify(!notify)}
        message={errors.detail ? errors.detail : errors.message}
        severity={errors.success ? "success" : "error"}
      />
    </Box>
  );
}
